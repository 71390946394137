import {createAction, props} from '@ngrx/store';


export class FakturierungsbelegContentActions {

  public static downloadBelegPdf = createAction(
    '[Fakturierungsbeleg Content] Download PDF file',
    props<{
      betriebId: string;
      belegId: string;
      statusEntwurf: boolean;
      isPreviewMode: boolean;
      rechnungsnummer: string;
    }>(),
  );

  public static downloadBelegPdfSuccess = createAction(
    '[Fakturierungsbeleg Content] Download PDF file success',
  );

  public static downloadBelegPdfFailure = createAction(
    '[Fakturierungsbeleg Content] Download PDF file failure',
    props<{ error: any, statusEntwurf: boolean }>(),
  );

  public static createPdfPreviewObjectUrlSuccess = createAction(
    '[Fakturierungsbeleg Content] Created the Object-Url for the PDF file successful',
    props<{
      objectUrl: string;
    }>(),
  );

  public static revokeObjectUrl = createAction(
    '[Fakturierungsbeleg Content] Remove the Object-Url',
    props<{
      objectUrl: string;
    }>(),
  );
}
