import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {createSelector} from '@ngrx/store';


export class DeleteFakturierungsbelegDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isOpen: (state: AppState) => state.deleteFakturierungsbelegDialog.isOpen,
    betriebId: (state: AppState) => state.deleteFakturierungsbelegDialog.betriebId,
    belegId: (state: AppState) => state.deleteFakturierungsbelegDialog.belegId,
  };

  public static isDeleteFakturierungsbelegDialogOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static deleteData = createSelector(
    this.select.betriebId,
    this.select.belegId,
    (betriebId, belegId) => {
      return {
        betriebId,
        belegId,
      };
    }
  );
}
