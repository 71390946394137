import {createAction, props} from '@ngrx/store';


export class DeleteFakturierungsbelegDialogActions {

    public static open = createAction(
      '[Fakturierungsbeleg Table] Open delete fakturierungsbeleg dialog.',
      props<{
        betriebId: string;
        belegId: string;
      }>(),
    );

    public static close = createAction(
      '[Delete Fakturierungsbeleg Dialog] Close delete fakturierungsbeleg dialog.',
    );
}
