import {createAction, props} from '@ngrx/store';
import {PageableDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class AddPositionActions {

  public static readProdukte = createAction(
    '[Add Position] Read Produkte at add-position component.',
    props<{
      betriebId: string,
      pageableDto: PageableDTO,
    }>(),
  );

  public static readMoreProdukte = createAction(
    '[Add Position] Read more produkte at add-position component.',
    props<{
      betriebId: string,
      pageableDto: PageableDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    '[Add Position] Set produkt ids that should be displayed at add-position component.',
    props<{
      produktDtos: ProduktDTO[],
    }>(),
  );

  public static addDisplayedIds = createAction(
    '[Add Position] Adds more produkt ids that should be displayed at add-position component.',
    props<{
      produktDtos: ProduktDTO[],
    }>(),
  );

  public static addDisplayedId = createAction(
    '[Add Position] Adds a single produkt-id that should be displayed at add-position component.',
    props<{
      produktId?: string,
    }>(),
  );

  public static clearDisplayedProdukte = createAction(
    '[Add Position] Clear produkte that should be displayed at add-position component.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateFilter = createAction(
    '[Add Position] Updates the filter at add-position component.',
    props<{
      filter: string[],
    }>(),
  );

  public static triggerReadMoreProdukte = createAction(
    '[Add Position] Updates the pageableDto to load more options add-position component.',
  );

}
