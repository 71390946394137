import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {identity} from 'rxjs';


export class FakturierungsbelegFormSelectors {
  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isFormValid: (state: AppState) => state.fakturierungsbelegForm.isFormValid,
    fakturierungsbelegDto: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg,
    positionen: (state: AppState) => state.fakturierungsbelegForm.positionen,
    logoUrl: (state: AppState) => state.fakturierungsbelegForm.logoUrl,
    leistungsempfaengerMode: (state: AppState) => state.fakturierungsbelegForm.leistungsempfaengerMode,
    vorlaufzeileMode: (state: AppState) => state.fakturierungsbelegForm.vorlaufzeileMode,
    vorlaufzeile: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg.vorlaufzeile,
    positionAnzahlModes: (state: AppState) => state.fakturierungsbelegForm.positionAnzahlModes,
    positionEinzelpreisNettoModes: (state: AppState) => state.fakturierungsbelegForm.positionEinzelpreisNettoModes,
    addPositionMode: (state: AppState) => state.fakturierungsbelegForm.addPositionMode,
    rechnungsdatum: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg?.datum,
    kundeId: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg?.kundeId,
    kunde: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg?.kunde,
    rechnungsdatumMode: (state: AppState) => state.fakturierungsbelegForm.rechnungsdatumMode,
    leistungsLieferdatumControlMode: (state: AppState) => state.fakturierungsbelegForm.leistungsLieferdatumControlMode,
    leistungsLieferdatumDateMode: (state: AppState) => state.fakturierungsbelegForm.leistungsLieferdatumDateMode,
    absenderZeile: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg.betrieb?.absenderZeile,
    fakturierungsbeleg: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbeleg,
  };

  /**
   * Selektiert die fakturierungsbelegDto.
   */
  public static fakturierungsbelegDto = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegDto,
    identity,
  );

  /**
   * Selektiert die belegId.
   */
  public static belegId = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegDto,
    fakturierungsbelegDto => fakturierungsbelegDto?.id
  );

  /**
   * Selektiert den isFormValid.
   */
  public static isFormValid = createSelector(
    FakturierungsbelegFormSelectors.select.isFormValid,
    identity,
  );

  /**
   * Selektiert die Kunden-ID des Fakturierungsbelegs.
   */
  public static kundeId = createSelector(
    FakturierungsbelegFormSelectors.select.kundeId,
    identity,
  );

  /**
   * Selektiert den Kunden des Fakturierungsbelegs.
   */
  public static kunde = createSelector(
    FakturierungsbelegFormSelectors.select.kunde,
    identity,
  );

  /**
   * Selektiert die Positionen des Fakturierungsbelegs.
   */
  public static positionen = createSelector(
    FakturierungsbelegFormSelectors.select.positionen,
    identity,
  );

  /**
   * Selektiert den LeistungsempfaengerMode.
   */
  public static leistungsempfaengerMode = createSelector(
    FakturierungsbelegFormSelectors.select.leistungsempfaengerMode,
    identity,
  );

  /**
   * Selektiert den VorlaufzeileMode.
   */
  public static vorlaufzeileMode = createSelector(
    FakturierungsbelegFormSelectors.select.vorlaufzeileMode,
    identity,
  );

  /**
   * Selektiert die Vorlaufzeile des Fakturierungsbelegs.
   */
  public static vorlaufzeile = createSelector(
    FakturierungsbelegFormSelectors.select.vorlaufzeile,
    identity,
  );

  /**
   * Selektiert das Logo des Inhabers.
   */
  public static logoUrl = createSelector(
    this.select.logoUrl,
    identity,
  );

  /**
   * Selektiert die Position-Anzahl-Modi der Positionen.
   */
  public static positionAnzahlModes = createSelector(
    FakturierungsbelegFormSelectors.select.positionAnzahlModes,
    identity,
  );

  /**
   * Selektiert die Betragssumme-Modi der Positionen.
   */
  public static positionEinzelpreisNettoModes = createSelector(
    FakturierungsbelegFormSelectors.select.positionEinzelpreisNettoModes,
    identity,
  );

  /**
   * Selektiert den Modus der Produkt-Hinzufügen-Funktion.
   */
  public static addPositionMode = createSelector(
    FakturierungsbelegFormSelectors.select.addPositionMode,
    identity,
  );

  /**
   * Selektiert das Rechnungsdatum.
   */
  public static rechnungsdatum = createSelector(
    FakturierungsbelegFormSelectors.select.rechnungsdatum,
    identity,
  );

  /**
   * Selektiert den Modus des Rechnungsdatums.
   */
  public static rechnungsdatumMode = createSelector(
    FakturierungsbelegFormSelectors.select.rechnungsdatumMode,
    identity,
  );

  /**
   * Selektiert den Modus des Controls des Leistungs- und Lieferdatum.
   */
  public static leistungsLieferdatumControlMode = createSelector(
    FakturierungsbelegFormSelectors.select.leistungsLieferdatumControlMode,
    identity,
  );

  /**
   * Selektiert den Modus des Leistungs- und Lieferdatum.
   */
  public static leistungsLieferdatumDateMode = createSelector(
    FakturierungsbelegFormSelectors.select.leistungsLieferdatumDateMode,
    identity,
  );

  /**
   * Selektiert den Text der Absenderzeile.
   */
  public static absenderZeile = createSelector(
    FakturierungsbelegFormSelectors.select.absenderZeile,
    identity,
  );

  /**
   * Selektiert den aktuellen Fakturierungsbeleg.
   */
  public static fakturierungsbeleg = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbeleg,
    identity,
  );

  /**
   * Selektiert alle Informationen für die Fusszeile.
   */
  public static fusszeile = createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbeleg,
    fakturierungsbeleg => {

      return {
        adresseFusszeile: fakturierungsbeleg?.betrieb?.adresseFusszeile ?? undefined,
        email: fakturierungsbeleg?.einstellung?.kontakt?.emailAdresse ?? undefined,
        telefon: fakturierungsbeleg?.einstellung?.kontakt?.telefonnummer ?? undefined,
        vorname: fakturierungsbeleg?.einstellung?.kontakt?.vorname ?? undefined,
        nachname: fakturierungsbeleg?.einstellung?.kontakt?.nachname ?? undefined,
        internetadresse: fakturierungsbeleg?.einstellung?.kontakt?.internetadresse ?? undefined,
        steuernummer: fakturierungsbeleg.betrieb?.steuernummer ?? undefined,
        ustIdNummer: fakturierungsbeleg.betrieb?.ustIdNummer ?? undefined,
        glaeubigerId: fakturierungsbeleg.betrieb?.glaeubigerId ?? undefined,
        iban: fakturierungsbeleg.einstellung?.bankkonto?.iban ?? undefined,
        bic: fakturierungsbeleg.einstellung?.bankkonto?.bic ?? undefined,
        bankbezeichnung: fakturierungsbeleg.einstellung?.bankkonto?.bankbezeichnung ?? undefined,
      };
    }
  );
}
