/**
 * Fakturierung
 * API für Fakturierung
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateNachlaufzeileRequestDTO } from '../model/models';
import { NachlaufzeileDTO } from '../model/models';
import { UpdateNachlaufzeileRequestDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class NachlaufzeileService {

    protected basePath = 'https://api.adnova.de/fakturierung-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Erstellen einer neuen Nachlaufzeile
     * Erstellt eine neue Nachlaufzeile zu einem Beleg.
     * @param betriebId Id die einen Betrieb identifiziert. 
     * @param belegId Id die einen Beleg identifiziert. 
     * @param createNachlaufzeileRequestDTO Daten der Nachlaufzeile, die erstellt werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNachlaufzeile(betriebId: string, belegId: string, createNachlaufzeileRequestDTO: CreateNachlaufzeileRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<NachlaufzeileDTO>;
    public createNachlaufzeile(betriebId: string, belegId: string, createNachlaufzeileRequestDTO: CreateNachlaufzeileRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<NachlaufzeileDTO>>;
    public createNachlaufzeile(betriebId: string, belegId: string, createNachlaufzeileRequestDTO: CreateNachlaufzeileRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<NachlaufzeileDTO>>;
    public createNachlaufzeile(betriebId: string, belegId: string, createNachlaufzeileRequestDTO: CreateNachlaufzeileRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (betriebId === null || betriebId === undefined) {
            throw new Error('Required parameter betriebId was null or undefined when calling createNachlaufzeile.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling createNachlaufzeile.');
        }
        if (createNachlaufzeileRequestDTO === null || createNachlaufzeileRequestDTO === undefined) {
            throw new Error('Required parameter createNachlaufzeileRequestDTO was null or undefined when calling createNachlaufzeile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<NachlaufzeileDTO>(`${this.configuration.basePath}/api/v1/betriebe/${encodeURIComponent(String(betriebId))}/belege/${encodeURIComponent(String(belegId))}/nachlaufzeilen`,
            createNachlaufzeileRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Löschen einer Nachlaufzeile.
     * Löscht eine Nachlaufzeile zu der UUID.
     * @param betriebId Id die einen Betrieb identifiziert. 
     * @param belegId Id die einen Beleg identifiziert. 
     * @param nachlaufzeileId Id die eine Nachlaufzeile identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (betriebId === null || betriebId === undefined) {
            throw new Error('Required parameter betriebId was null or undefined when calling deleteNachlaufzeile.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling deleteNachlaufzeile.');
        }
        if (nachlaufzeileId === null || nachlaufzeileId === undefined) {
            throw new Error('Required parameter nachlaufzeileId was null or undefined when calling deleteNachlaufzeile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/betriebe/${encodeURIComponent(String(betriebId))}/belege/${encodeURIComponent(String(belegId))}/nachlaufzeilen/${encodeURIComponent(String(nachlaufzeileId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abruf einer einzelnen Nachlaufzeile.
     * Laden einer Nachlaufzeile zu der UUID.
     * @param betriebId Id die einen Betrieb identifiziert. 
     * @param belegId Id die einen Beleg identifiziert. 
     * @param nachlaufzeileId Id die eine Nachlaufzeile identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<NachlaufzeileDTO>;
    public getNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<NachlaufzeileDTO>>;
    public getNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<NachlaufzeileDTO>>;
    public getNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (betriebId === null || betriebId === undefined) {
            throw new Error('Required parameter betriebId was null or undefined when calling getNachlaufzeile.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling getNachlaufzeile.');
        }
        if (nachlaufzeileId === null || nachlaufzeileId === undefined) {
            throw new Error('Required parameter nachlaufzeileId was null or undefined when calling getNachlaufzeile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<NachlaufzeileDTO>(`${this.configuration.basePath}/api/v1/betriebe/${encodeURIComponent(String(betriebId))}/belege/${encodeURIComponent(String(belegId))}/nachlaufzeilen/${encodeURIComponent(String(nachlaufzeileId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen aller Nachlaufzeilen zu einem Beleg
     * Gibt alle Nachlaufzeilen für den angegebenen Beleg zurück. 
     * @param betriebId Id die einen Betrieb identifiziert. 
     * @param belegId Id die einen Beleg identifiziert. 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readNachlaufzeilen(betriebId: string, belegId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<NachlaufzeileDTO>>;
    public readNachlaufzeilen(betriebId: string, belegId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<NachlaufzeileDTO>>>;
    public readNachlaufzeilen(betriebId: string, belegId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<NachlaufzeileDTO>>>;
    public readNachlaufzeilen(betriebId: string, belegId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (betriebId === null || betriebId === undefined) {
            throw new Error('Required parameter betriebId was null or undefined when calling readNachlaufzeilen.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling readNachlaufzeilen.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<NachlaufzeileDTO>>(`${this.configuration.basePath}/api/v1/betriebe/${encodeURIComponent(String(betriebId))}/belege/${encodeURIComponent(String(belegId))}/nachlaufzeilen`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update einer Nachlaufzeile.
     * Aktualisiert eine Nachlaufzeile zu der UUID.
     * @param betriebId Id die einen Betrieb identifiziert. 
     * @param belegId Id die einen Beleg identifiziert. 
     * @param nachlaufzeileId Id die eine Nachlaufzeile identifiziert. 
     * @param updateNachlaufzeileRequestDTO Daten der Nachlaufzeile, die aktualisiert werden soll
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, updateNachlaufzeileRequestDTO: UpdateNachlaufzeileRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<NachlaufzeileDTO>;
    public updateNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, updateNachlaufzeileRequestDTO: UpdateNachlaufzeileRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<NachlaufzeileDTO>>;
    public updateNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, updateNachlaufzeileRequestDTO: UpdateNachlaufzeileRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<NachlaufzeileDTO>>;
    public updateNachlaufzeile(betriebId: string, belegId: string, nachlaufzeileId: string, updateNachlaufzeileRequestDTO: UpdateNachlaufzeileRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (betriebId === null || betriebId === undefined) {
            throw new Error('Required parameter betriebId was null or undefined when calling updateNachlaufzeile.');
        }
        if (belegId === null || belegId === undefined) {
            throw new Error('Required parameter belegId was null or undefined when calling updateNachlaufzeile.');
        }
        if (nachlaufzeileId === null || nachlaufzeileId === undefined) {
            throw new Error('Required parameter nachlaufzeileId was null or undefined when calling updateNachlaufzeile.');
        }
        if (updateNachlaufzeileRequestDTO === null || updateNachlaufzeileRequestDTO === undefined) {
            throw new Error('Required parameter updateNachlaufzeileRequestDTO was null or undefined when calling updateNachlaufzeile.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<NachlaufzeileDTO>(`${this.configuration.basePath}/api/v1/betriebe/${encodeURIComponent(String(betriebId))}/belege/${encodeURIComponent(String(belegId))}/nachlaufzeilen/${encodeURIComponent(String(nachlaufzeileId))}`,
            updateNachlaufzeileRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
