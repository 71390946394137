import {Injectable} from '@angular/core';
import {AddPositionActions} from '../actions/add-position.actions';
import {ProduktEntitiesEffects} from './produkt-entities.effects';


@Injectable()
export class AddPositionEffects {

  constructor(
    private produktEntitiesEffects: ProduktEntitiesEffects
  ) {
  }

  readonly readProdukte$ = this.produktEntitiesEffects.readProdukte$(
    AddPositionActions.readProdukte,
    AddPositionActions.setDisplayedIds,
    AddPositionActions.clearDisplayedProdukte,
  );

  readonly readMoreProdukte$ = this.produktEntitiesEffects.readProdukte$(
    AddPositionActions.readMoreProdukte,
    AddPositionActions.addDisplayedIds,
    AddPositionActions.clearDisplayedProdukte,
  );

}
