<div
  class="formfields-absender-wrapper"
  *ngIf="formControls && anredeOptions && landOptions"
>
  <mat-divider class="topline"></mat-divider>

  <jf-form-field-text
    class="firma"
    [formControl]="formControls.controls.firma"
    [label]="'Firma'"
  ></jf-form-field-text>

  <jf-form-field-number
    class="kundennummer"
    [message]="kundennummerValidatorMessage"
    [formControl]="formControls.controls.kundennummer"
    [label]="'Kundennummer'"
  ></jf-form-field-number>

  <jf-form-field-text
    class="strasse"
    [formControl]="formControls.controls.strasseHausnummer"
    [label]="'Straße + Hausnummer'"
  ></jf-form-field-text>

  <jf-form-field-text
    class="plz"
    [maxlength]="5"
    [formControl]="formControls.controls.plz"
    [label]="'PLZ'"
    [message]="plzValidatorMessage"
  ></jf-form-field-text>

  <jf-form-field-text
    class="ort"
    [formControl]="formControls.controls.ort"
    [message]="ortValidatorMessage"
    [label]="'Ort'"
  ></jf-form-field-text>

  <jf-form-field-select
    class="land"
    [formControl]="formControls.controls.landGeschaeft"
    [options]="landOptions"
    [label]="'Land'"
  ></jf-form-field-select>

  <jf-form-field-text
    class="ustid"
    [formControl]="formControls.controls.ustId"
    [message]="ustIdValidatorMessage"
    [label]="'USt-ID'">
  </jf-form-field-text>

  <jf-form-field-text
    class="email"
    [formControl]="formControls.controls.email"
    [label]="'E-Mail-Adresse'"
    [message]="emailValidatorMessage"
  ></jf-form-field-text>

</div>

<mat-divider class="inbetween"></mat-divider>

<h2>Ansprechpartner (optionale Angaben)</h2>

<div
  class="formfields-ansprechpartner-wrapper"
  *ngIf="formControls && anredeOptions && landOptions"
>
  <jf-form-field-select
    class="anrede"
    [label]="'Anrede'"
    [formControl]="formControls.controls.ansprechpartnerAnrede"
    [options]="anredeOptions"
  ></jf-form-field-select>

  <jf-form-field-text
    class="titel"
    [label]="'Titel'"
    [formControl]="formControls.controls.ansprechpartnerTitel"
  ></jf-form-field-text>

  <jf-form-field-text
    class="vorname"
    [label]="'Vorname'"
    [formControl]="formControls.controls.ansprechpartnerVorname"
  ></jf-form-field-text>

  <jf-form-field-text
    class="nachname"
    [formControl]="formControls.controls.ansprechpartnerNachname"
    [label]="'Nachname'"
  ></jf-form-field-text>
</div>
