import {Component, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {BelegeEmptyStateDialogActions} from '../../../store/actions/belege-empty-state-dialog.actions';
import {AppState} from '../../../store/states/app.state';
import {InhaberEntitiesActions, InhaberEntitiesSelectors, RouterActions} from '@adnova/jf-ng-components';
import {take} from 'rxjs';
import {FakturierungsbelegEntitiesActions} from '../../../store/actions/fakturierungsbeleg-entities.actions';


@Component({
  selector: 'app-empty-belege-dialog',
  templateUrl: './empty-belege-dialog.component.html',
  styleUrls: ['./empty-belege-dialog.component.scss']
})
export class EmptyBelegeDialogComponent {

  private _store = inject(Store<AppState>);

  protected readonly benefitsList = [
    {
      text: 'Unterstützt bei der Erstellung rechtskonformer* Rechnungen',
    },
    {
      text: 'Aktuelle branchenspezifische USt-Sätze',
    },
  ];

  closeDialogClick(): void {
    this._store.dispatch(BelegeEmptyStateDialogActions.close());
  }

  redirectFakturierungClick(): void {
    this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      take(1),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;

      this.closeDialogClick();

      this._store.dispatch(FakturierungsbelegEntitiesActions.createFakturierungsbelegWithCurrentBetriebId({redirectToForm: true}))
    });
  }
}
