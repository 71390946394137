import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {NGXLogger} from "ngx-logger";
import {BelegContentService, PreviewService} from 'src/app/openapi/fakturierung-openapi';
import {FakturierungsbelegContentActions} from "../actions/fakturierungsbeleg-content.actions";
import {catchError, concatMap, map, of, switchMap, tap} from 'rxjs';
import {PdfDownloadService} from "src/app/services/pdf-download.service";
import {mappedHttpErrorResponseOperator} from "@adnova/jf-ng-components";


@Injectable()
export class FakturierungsbelegContentEffects {
  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private belegContentService: BelegContentService,
    private pdfDownloadService: PdfDownloadService,
    private previewService: PreviewService,
  ) {
  }

  /**
   * Der Effekt wird zum Laden der Dateien der Fakturierungsbelege verwendet.
   * Der Status des Belegs wird überprüft, um festzustellen, ob es sich um einen Entwurf handelt.
   * In dem Fall wird der Preview-Service verwendet, um die Vorschau des Belegs herunterzuladen, bei einem
   * festgeschriebenen Beleg wird der Beleg-Content-Service verwendet.
   * @Param betriebId - Die ID des Betriebs
   * @Param belegId - Die ID des Belegs
   * @Param statusEntwurf - Der Status des Belegs
   */
  readonly downloadBelegContent$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegContentActions.downloadBelegPdf),
      concatMap((action) => {
        let downloadService$ = action.statusEntwurf
        ? this.previewService.downloadPdfPreview(action.betriebId, action.belegId)
        : this.belegContentService.downloadBelegPdfContent(action.betriebId, action.belegId);

        return downloadService$.pipe(
          map(response => {
            this.logger.debug(
              'pdf file downloaded successfully',
            );
            let fileName = action.statusEntwurf ? `Rechnung_Entwurf_${action.belegId}.pdf` : `Rechnung_${action.rechnungsnummer}.pdf`;
            this.pdfDownloadService.downloadPDF(response, fileName, action.belegId, action.isPreviewMode);
            return FakturierungsbelegContentActions.downloadBelegPdfSuccess();
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'error downloading pdf file',
                error,
              );

              return FakturierungsbelegContentActions.downloadBelegPdfFailure({
                error,
                statusEntwurf: action.statusEntwurf
              })
            })
          ))
        );
      })
    )
  );

  readonly downloadBelegPdfFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegContentActions.downloadBelegPdfFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 404 : {
            errorMsg = 'Der Beleg zu dieser Rechnung konnte nicht gefunden werden.';
            break;
          }
          case 422 : {
            errorMsg = 'Fehler beim Herunterladen des Belegs. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 503 : {
            errorMsg = 'Abruf des PDF vorübergehend nicht möglich. Bitte probiere es später erneut.';
            break;
          }

          default: {
            errorMsg = 'Abruf des PDF vorübergehend nicht möglich. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          'Schließen',
          {duration: 5000}
        );
      })
    ),
    {dispatch: false}
  );

  readonly revokeObjectUrl$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegContentActions.revokeObjectUrl),
      tap(action => {
        this.pdfDownloadService.revokeObjectUrl(action.objectUrl);
      }),
    ),
    {dispatch: false}
  );
}
