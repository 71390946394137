<div
  class='formfield-wrapper'
  *ngIf="formControls && einheitenOptions && berechnungsarten && umsatzsteuersaetze"
>
  <jf-form-field-text
    class="title"
    [label]="'Produkt'"
    [formControl]="formControls.controls.produktBezeichnung"
  ></jf-form-field-text>

  <jf-form-field-select
    class="einheit"
    [label]="'Einheit'"
    [formControl]="formControls.controls.einheit"
    [options]="einheitenOptions"
  ></jf-form-field-select>

  <jf-form-field-number
    class="preis"
    [label]="'Preis in €'"
    [formControl]="formControls.controls.betragssumme"
    [truncate]="2"
    [roundUpDecimalPlaces]="true"
    [thousandSeparator]="'.'"
    [decimalSeparator]="','"
    [fillWithZeros]="true"
    [minValue]="-99999999.99"
    [maxValue]="99999999.99"
  ></jf-form-field-number>

  <jf-form-field-select
    class="berechnungsart"
    [label]="'Preisberechnung in:'"
    [formControl]="formControls.controls.berechnungsart"
    [options]="berechnungsartenOptions"
    [message]="'Derzeit nur Netto möglich.'"
  ></jf-form-field-select>

  <jf-form-field-select
    class="ustSatz"
    [label]="'Umsatzsteuersatz'"
    [formControl]="formControls.controls.ustProzentsatz"
    [options]="umsatzsteuersaetzeOptions"
  ></jf-form-field-select>

  <jf-form-field-text
    class="beschreibung"
    [formControl]="formControls.controls.produktbeschreibung"
    [label]="'Produktbeschreibung'"
    [message]="'Produkt und Produktbeschreibung werden auf der Rechnung angezeigt'"
  ></jf-form-field-text>

  <jf-form-field-number
    class="produktnummer"
    [label]="'Produktnummer'"
    [formControl]="formControls.controls.produktnummer"
  ></jf-form-field-number>
</div>
