import {Component, DestroyRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent,
} from '@adnova/jf-ng-components';
import {MatDividerModule} from '@angular/material/divider';
import {LandDTO} from '../../../../openapi/schluesseldaten-openapi';
import {GeschaeftskundendatenDTO, KundeDTO} from '../../../../openapi/fakturierung-openapi';
import {KundeGeschaeftskundeFormControls} from './kunde-geschaeftskunde-form.interface';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {debounceTime} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DeepPartial} from '../../../../types/deep-partial';


@Component({
  selector: 'app-kunde-geschaeftskunde-form',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldNumberComponent,
    FormFieldSelectComponent,
    FormFieldTextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
  ],
  templateUrl: './kunde-geschaeftskunde-form.component.html',
  styleUrls: ['./kunde-geschaeftskunde-form.component.scss']
})
export class KundeGeschaeftskundeFormComponent implements OnChanges {

  private _destroyRef = inject(DestroyRef);

  private _kunde?: DeepPartial<KundeDTO>;
  private _anreden: string[] = [];
  private _anredeOptions: OptionComponent[] = [];
  private _laender: LandDTO[] = [];
  private _landOptions: OptionComponent[] = [];
  private _formControls?: FormGroup<KundeGeschaeftskundeFormControls>;
  private _emailValidatorMessage = '';
  private _plzValidatorMessage = '';
  private _kundennummerValidatorMessage = '';
  private _ustIdValidatorMessage = '';
  private _ortValidatorMessage = '';

  @Output()
  kundeChanged = new EventEmitter<DeepPartial<KundeDTO>>();

  @Input()
  get kunde(): DeepPartial<KundeDTO> | undefined {
    return this._kunde;
  }

  set kunde(value: DeepPartial<KundeDTO> | undefined) {
    this._kunde = value;
  }

  get kundendaten(): DeepPartial<GeschaeftskundendatenDTO> | undefined {
    return this.kunde?.kundendaten;
  }

  @Input()
  get anreden(): string[] {
    return this._anreden;
  }

  set anreden(value: string[]) {
    this._anreden = value;
  }

  get anredeOptions(): OptionComponent[] {
    return this._anredeOptions;
  }

  set anredeOptions(value: OptionComponent[]) {
    this._anredeOptions = value;
  }

  @Input()
  get laender(): LandDTO[] {
    return this._laender;
  }

  set laender(value: LandDTO[]) {
    this._laender = value;
  }

  get landOptions(): OptionComponent[] {
    return this._landOptions;
  }

  set landOptions(value: OptionComponent[]) {
    this._landOptions = value;
  }

  @Input()
  get formControls(): FormGroup<KundeGeschaeftskundeFormControls> | undefined {
    return this._formControls;
  }

  set formControls(value: FormGroup<KundeGeschaeftskundeFormControls> | undefined) {
    this._formControls = value;
  }

  get emailValidatorMessage(): string {
    return this._emailValidatorMessage;
  }

  get plzValidatorMessage(): string {
    return this._plzValidatorMessage;
  }

  get kundennummerValidatorMessage(): string {
    return this._kundennummerValidatorMessage;
  }

  get ustIdValidatorMessage(): string {
    return this._ustIdValidatorMessage
  }

  get ortValidatorMessage(): string {
    return this._ortValidatorMessage;
  }

  /*
   * INFO: Wenn durch Validators.min(10000) ein Fehler auftritt, soll ein entsprechender Text ausgegeben werden.
   */
  getKundennummerValidatorMessage(): string {
    const control = this.formControls?.controls.kundennummer;
    const errors = control?.errors;
    if (errors?.min) {
      return 'Kundennummer muss mindestens fünfstellig sein.';
    }
    return '';
  }

  getEmailValidatorMessage(): string {
    const control = this.formControls?.controls.email;
    const errors = control?.errors;
    if (errors?.email || errors?.pattern) {
      return 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
    }
    return '';
  }

  getPlzValidatorMessage(): string {
    const control = this.formControls?.controls.plz;
    const errors = control?.errors;
    if (errors?.minlength || errors?.maxlength) {
      return 'Muss fünfstellig sein.';
    } else if (errors?.pattern) {
      return 'Darf nur Zahlen enthalten';
    }
    return '';
  }

  getUstIdValidatorMessage(): string {
    const control = this.formControls?.controls.ustId;
    const errors = control?.errors;

    if (errors?.invalidVatFormat) {
      return 'Das Format der USt-Id ist ungültig. Bitte überprüfen Sie Ihre Eingabe.';
    }

    if (errors?.invalidVatId) {
      return 'Die eingegebene USt-Id ist ungültig. Bitte überprüfen Sie die Nummer.';
    }

    // return 'Pflichtangabe für Verkäufe außerhalb Deutschlands.'; //INFO: will be displayed again in the future.
    return '';
  }


  getOrtValidatorMessage(): string {
    const control = this.formControls?.controls.ort;
    const errors = control?.errors;
    if (errors?.pattern) {
      return 'Darf keine Sonderzeichen enthalten';
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.anreden) {
      this.anredeOptions = this.generateAnredenOptions();
    }

    if (changes.laender) {
      this.landOptions = this.generateLandOptions();
    }

    if (changes.kunde || changes.formControls) {
      this.updateKundendaten();
    }

    if (changes.formControls) {
      this.emitOnFormChanges();
    }
  }

  private updateKundendaten(): void {

    this.anredeOptions = this.generateAnredenOptions();
    this.landOptions = this.generateLandOptions();

    const kunde = this.kunde;
    const kundendaten = this.kundendaten;
    const adresse = this.kunde?.adresse;

    if (kunde?.kundennummer !== undefined) {
      this.formControls?.controls.kundennummer.setValue(kunde.kundennummer);
    }

    if (kundendaten?.firmenbezeichnung) {
      this.formControls?.controls.firma.setValue(kundendaten.firmenbezeichnung);
    }

    if (kundendaten?.ustIdNummer) {
      this.formControls?.controls.ustId.setValue(kundendaten.ustIdNummer);
    }

    if (kundendaten?.emailAdresse) {
      this.formControls?.controls.email.setValue(kundendaten.emailAdresse);
    }

    if (kundendaten?.ansprechpartner?.titel) {
      this.formControls?.controls.ansprechpartnerTitel.setValue(kundendaten.ansprechpartner.titel);
    }

    if (kundendaten?.ansprechpartner?.vorname) {
      this.formControls?.controls.ansprechpartnerVorname.setValue(kundendaten.ansprechpartner.vorname);
    }

    if (kundendaten?.ansprechpartner?.nachname) {
      this.formControls?.controls.ansprechpartnerNachname.setValue(kundendaten.ansprechpartner.nachname);
    }

    if (adresse?.strasseHausnummer) {
      this.formControls?.controls.strasseHausnummer.setValue(adresse.strasseHausnummer);
    }

    if (adresse?.postleitzahl) {
      this.formControls?.controls.plz.setValue(adresse.postleitzahl);
    }

    if (adresse?.ort) {
      this.formControls?.controls.ort.setValue(adresse.ort);
    }

  }

  private generateAnredenOptions(): OptionComponent[] {
    const selectedAnrede = this.kundendaten?.ansprechpartner?.anrede;
    return this.anreden.map(anrede => {
      const option = new OptionComponent();
      option.id = anrede;
      option.label = anrede;
      option.isSelected = !!selectedAnrede && selectedAnrede === anrede;
      return option;
    });
  }

  private generateLandOptions(): OptionComponent[] {
    const selectedLand = this.kunde?.adresse?.land;
    return this.laender.map(land => {
      const option = new OptionComponent();
      option.id = land.kurzform;
      option.label = land.kurzform;
      option.isSelected = (!!selectedLand && selectedLand === land.kurzform) || option.label === 'Deutschland';
      return option;
    });
  }

  private emitOnFormChanges() {
    this.formControls?.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
      debounceTime(100),
    ).subscribe(value => {
      this.kunde = {
        ...this.kunde,
        kundennummer: value.kundennummer === null ? undefined : value.kundennummer,
        adresse: {
          ...this.kunde?.adresse,
          ort: value.ort || undefined,
          postleitzahl: value.plz || undefined,
          land: value.landGeschaeft?.selectedOptionValueIds?.at(0),
          strasseHausnummer: value.strasseHausnummer || undefined,
        },
        kundendaten: {
          ...this.kundendaten,
          ansprechpartner: {
            ...this.kundendaten?.ansprechpartner,
            vorname: value.ansprechpartnerVorname || undefined,
            nachname: value.ansprechpartnerNachname || undefined,
            titel: value.ansprechpartnerTitel || undefined,
            anrede: value.ansprechpartnerAnrede?.selectedOptionValueIds?.at(0),
          },
          ustId: value.ustId || undefined,
          emailAdresse: value.email || undefined,
          firmenbezeichnung: value.firma || undefined,
        } as GeschaeftskundendatenDTO,
      };
      this.setValidatorMessages();
      this.kundeChanged.emit(this.kunde);
    });
  }

  private setValidatorMessages() {
    this._emailValidatorMessage = this.getEmailValidatorMessage();
    this._plzValidatorMessage = this.getPlzValidatorMessage();
    this._kundennummerValidatorMessage = this.getKundennummerValidatorMessage();
    this._ustIdValidatorMessage = this.getUstIdValidatorMessage();
    this._ortValidatorMessage = this.getOrtValidatorMessage();
  }
}
