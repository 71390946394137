import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {ProduktDialogActions} from '../../../store/actions/produkt-dialog.actions';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {InhaberEntitiesSelectors} from '@adnova/jf-ng-components';
import {debounceTime, take} from 'rxjs';
import {filter} from 'rxjs/operators';
import {
  BerechnungsregelDTO,
  BerechnungsregelMengeMalEinzelpreisDTO,
  ProduktDTO,
  ProduktRequestDTO,
} from '../../../openapi/fakturierung-openapi';
import {FormInput} from '../../components/form/form-input.interface';
import {ProduktDialogSelectors} from '../../../store/selectors/produkt-dialog.selectors';
import {MengeneinheitExtended} from '../../../interfaces/mengeneinheit-extended.interface';
import {UmsatzsteuerschluesselExtended} from '../../../interfaces/umsatzsteuerschluessel-extended.interface';
import {ProduktEntitiesActions} from '../../../store/actions/produkt-entities.actions';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DeepPartial} from '../../../types/deep-partial';
import {FormType} from '../../../interfaces/form.type';
import {MengeneinheitEntitiesSelectors} from '../../../store/selectors/mengeneinheit-entities.selectors';
import {
  UmsatzsteuerschluesselEntitiesSelectors
} from '../../../store/selectors/umsatzsteuerschluessel-entities.selectors';
import {ProduktEntitiesSelectors} from '../../../store/selectors/produkt-entities.selectors';
import {ProduktFormControls} from './produkt-form/produkt-form-controls.interface';


@Component({
  selector: 'app-create-produkt-dialog',
  templateUrl: './produkt-dialog.component.html',
  styleUrls: ['./produkt-dialog.component.scss']
})
export class ProduktDialogComponent implements OnInit {

  private _store = inject(Store<AppState>);
  private _destroyRef = inject(DestroyRef);
  private _addToInvoice: boolean = false;
  private _einheiten: MengeneinheitExtended[] = [];
  private _umsatzsteuersaetze: UmsatzsteuerschluesselExtended[] = [];
  private _berechnungsarten = ['Netto', 'Brutto'];
  private _produkteFormType = FormType.CREATE;
  private _primaryButtonLabel = 'Produkt anlegen';
  private _produktDto?: DeepPartial<ProduktDTO>;
  private _berechnungsregel?: BerechnungsregelDTO;

  private _formInputs: FormInput = {
    title: 'Neues Produkt anlegen',
    cancelButtonLabel: 'Abbrechen',
  };

  private noEmptySelectionValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      return { emptyObject: true };
    }
    return null;
  };

  private _formControls: ProduktFormControls = {
    produktBezeichnung: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
    einheit: new FormControl({}, [Validators.required, this.noEmptySelectionValidator]),
    betragssumme: new FormControl(null),
    berechnungsart: new FormControl({value: null, disabled: true}, [Validators.required, this.noEmptySelectionValidator]),
    ustProzentsatz: new FormControl({}, [Validators.required, this.noEmptySelectionValidator]),
    produktbeschreibung: new FormControl('', [Validators.minLength(1), Validators.maxLength(2000)]),
    produktnummer: new FormControl(null, [Validators.required]),
  };

  private _formGroup = new FormGroup(this._formControls);
  private _betriebId = '';

  get produkteFormType(): FormType {
    return this._produkteFormType;
  }

  get einheiten(): MengeneinheitExtended[] {
    return this._einheiten;
  }

  get umsatzsteuersaetze(): UmsatzsteuerschluesselExtended[] {
    return this._umsatzsteuersaetze;
  }

  get berechnungsarten(): string[] {
    return this._berechnungsarten;
  }

  get primaryButtonLabel(): string {
    return this._primaryButtonLabel;
  }

  get produktDto(): DeepPartial<ProduktDTO> | undefined {
    return this._produktDto;
  }

  get formInputs(): FormInput {
    return this._formInputs;
  }

  get formControls(): ProduktFormControls {
    return this._formControls;
  }

  get formGroup(): FormGroup {
    return this._formGroup;
  }

  get berechnungsregel(): BerechnungsregelDTO | undefined {
    return this._berechnungsregel;
  }

  produktChanged(produkt: DeepPartial<ProduktDTO>): void {
    this._store.dispatch(ProduktDialogActions.changeProdukt({produkt}));
  }

  ngOnInit() {

    // INFO: Ermitteln der nächsten freien Produktnummer.
    this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      takeUntilDestroyed(this._destroyRef),
      filter((betriebId): betriebId is string => !!betriebId),
    ).subscribe(betriebId => {
      this._store.dispatch(ProduktEntitiesActions.detectNextProduktnummer({betriebId}));
      this.loadProdukt();

      // INFO: Laden der Einheiten.
      this._store.select(MengeneinheitEntitiesSelectors.mengeneinheitenByBetrieb(betriebId)).pipe(
        take(1),
      ).subscribe(mengeneinheiten => {
        this._einheiten = mengeneinheiten;
      });

      // INFO: Laden der Umsatzsteuersätze.
      this._store.select(UmsatzsteuerschluesselEntitiesSelectors.umsatzsteuerschluesselByBetrieb(betriebId)).pipe(
        take(1),
      ).subscribe(umsatzsteuersaetze => {
        this._umsatzsteuersaetze = umsatzsteuersaetze;
      });

    });

    // INFO: Setzen der aktuellen BetriebId.
    this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      take(1),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;
      this._betriebId = inhaberId;
    });

    // INFO: Handling für das Befüllen der Controls
    this._store.select(ProduktDialogSelectors.produkt).pipe(
      take(1),
    ).subscribe(produkt => {
      if (!produkt) return;

      if (produkt.id) {
        this._formInputs.title = 'Produkt bearbeiten';
      }
      this._primaryButtonLabel = 'Speichern';
      this._produkteFormType = FormType.EDIT;

    });

    // INFO: Setzen des Kennzeichens, ob das Produkt zur Rechnung hinzugefügt werden soll.
    this._store.select(ProduktDialogSelectors.addToInvoice).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(addToInvoice => {
      this._addToInvoice = addToInvoice || false;
    });

    // INFO: Schließen des Dialogs, wenn der Erstell- oder Speicher-Vorgang erfolgreich war.
    this._store.select(ProduktEntitiesSelectors.createSaveActionSuccessful).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(successful => {
      if (!successful) return;
      this._store.dispatch(ProduktDialogActions.close());
    });

    this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      filter((betriebId): betriebId is string => !!betriebId),
      take(1),
    ).subscribe(betriebId => {
      this._store.select(ProduktEntitiesSelectors.nextProduktnummerByBetriebId(betriebId)).pipe(
        filter((nextProduktnummer): nextProduktnummer is number => !!nextProduktnummer),
        take(1),
      ).subscribe(nextProduktnummer => {
        if (this._produktDto?.nummer !== undefined) {
          this.setNextProduktnummer(this._produktDto.nummer);
        } else {
          this.setNextProduktnummer(nextProduktnummer);
        }
      });
    });
  }

  // INFO: Absenden des Formulars.
  doConfirmClicked(): void {
    const produktRequestDto: ProduktRequestDTO = this.createProduktRequestDto();
    if (this.produktDto?.id) {
      this._store.dispatch(ProduktEntitiesActions.updateProdukt({
        betriebId: this._betriebId,
        produktId: this.produktDto.id,
        requestDto: produktRequestDto,
      }));
    } else {
      this._store.dispatch(ProduktEntitiesActions.createProdukt({
        betriebId: this._betriebId,
        requestDto: produktRequestDto,
        addToInvoice: this._addToInvoice,
      }));
    }
  }

  /**
   * Erstellt ein CreateProduktRequestDTO aus den Formularwerten.
   * Aktuell ist das CreateProduktRequestDTO sowie das UpdateProduktRequestDTO identisch.
   * Das ist aktuell OK und kann sich zukünftig ändern.
   *
   * @private
   */
  private createProduktRequestDto(): ProduktRequestDTO {
    const formControls = this.formControls;
    return {
      nummer: formControls.produktnummer.value || 0,
      bezeichnung: formControls.produktBezeichnung.value || '',
      beschreibung: formControls.produktbeschreibung.value || undefined,
      berechnungsregel: {
        // INFO: Erstmal nur diese Berechnungsregel, später können weitere hinzukommen.
        typ: 'BerechnungsregelMengeMalEinzelpreis',
        // INFO: Sämtliche Punkte entfernen, anschließend das Komma durch Punkt ersetzen
        betrag: this.formatNumberWithComma(formControls.betragssumme.value?.toString()),
        bruttoberechnung: formControls.berechnungsart.value?.selectedOptionValueIds?.at(0) === 'brutto',
        mengeneinheitNummer: formControls.einheit.value?.selectedOptionValueIds?.at(0) || 0,
        umsatzsteuerschluesselNummer: formControls.ustProzentsatz.value?.selectedOptionValueIds?.at(0) || 0,
      } as BerechnungsregelMengeMalEinzelpreisDTO,
    };
  }

  private formatNumberWithComma(value?: string | null): number | undefined {
    // INFO: Entferne alle Punkte (die Tausendertrennzeichen darstellen könnten) und Kommata
    if (value === undefined || value === null) return undefined;
    value = value?.replace('.', '').replace(',', '.') || '0';
    return parseFloat(value);
  }

  protected closeDialogClick(): void {
    this._store.dispatch(ProduktDialogActions.close());
  };

  /**
   * Überprüft, ob das Formular vollständig ist. Wenn folgende Bedingungen erfüllt sind, wird true zurückgegeben:
   * 1. Es muss geprüft werden, ob die Werte des Privatpersonen-Formulars verändert wurden und valide sind.
   * 2. Es muss geprüft werden, ob die Werte des Geschäftskunden-Formulars verändert wurden und valide sind.
   *
   * @protected
   */
  protected checkFormComplete(): boolean {
    const isComplete =
      this.formGroup.valid && this.formGroup.dirty;
    return isComplete;
  }

  private loadProdukt(): void {
    this._store.select(ProduktDialogSelectors.produkt).pipe(
      debounceTime(0), // INFO: Wird benötigt, um synchronisationsfehler zu vermeiden.
      take(1),
    ).subscribe(produkt => {
      if (!produkt) return;
      this._produktDto = produkt;
      this.produktChanged(this._produktDto);
    });
  }

  private setNextProduktnummer(nextProduktnummer?: number): void {
    nextProduktnummer = nextProduktnummer === undefined ? 10000 : nextProduktnummer;
    this._produktDto = {
      ...this._produktDto,
      nummer: nextProduktnummer,
    };
    this._store.dispatch(ProduktDialogActions.assignNextProduktnummer({nummer: nextProduktnummer}));
  }

}
