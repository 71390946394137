import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {concatMap, of, switchMap, take, tap} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {MappedHttpErrorResponse, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';
import {SentryActions} from '../actions/sentry.actions';
import {ProduktDialogActions} from '../actions/produkt-dialog.actions';
import {ActionCreator, Store} from '@ngrx/store';
import {ProduktEntitiesSelectors} from '../selectors/produkt-entities.selectors';
import {AppState} from '../states/app.state';
import {TypedAction} from '@ngrx/store/src/models';
import {mappedHttpErrorResponse} from '../../operator-services/mapped-http-error-response';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {DirectionDTO, PageableDTO, ProduktDTO, ProduktService} from '../../openapi/fakturierung-openapi';
import {DeleteProduktDialogActions} from '../actions/delete-produkt-dialog.actions';
import {AddPositionActions} from '../actions/add-position.actions';


@Injectable()
export class ProduktEntitiesEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private produktService: ProduktService,
    private store: Store<AppState>,
  ) {
  }

  /**
   * Der Effekt erlaubt asynchrones Laden von Produkte durch serverseitige Datenanfragen.
   * Dabei steuert er die Auslösung von Actions basierend auf dem Erfolg oder Misserfolg der Anfrage.
   * Er kann in unterschiedlichen Komponenten benutzt werden, um konsistente State-Management-Prozesse zu garantieren.
   *
   * @property onAction - Action Creator, der ausgeführt wird, um den Effekt zu starten
   * @property successAction - Action Creator, der ausgeführt wird, wenn die Daten erfolgreich vom Server abgerufen wurden
   * @property failureAction - Action Creator, der ausgeführt wird, wenn beim Abrufen der Daten ein Fehler aufgetreten ist
   */
  readonly readProdukte$ = (
    onAction: ActionCreator<any, (props: {
      betriebId: string,
      pageableDto: PageableDTO,
    }) => ({
      betriebId: string, pageableDto: PageableDTO,
    } & TypedAction<any>)>,
    successAction?: ActionCreator<any, (props: { produktDtos: ProduktDTO[], }) => ({
      produktDtos: ProduktDTO[]
    } & TypedAction<any>)>,
    failureAction?: ActionCreator<any, (props: { error: MappedHttpErrorResponse, }) => ({
      error: MappedHttpErrorResponse
    } & TypedAction<any>)>,
  ) => createEffect(
    () => this.actions$.pipe(
      ofType(onAction),
      switchMap(({
                   betriebId,
                   pageableDto,
                 }) => {

        return this.produktService.readProdukte(
          betriebId,
          pageableDto,
        ).pipe(
          switchMap(produktPageDto => {

            this.logger.debug(
              'read produkte succeeded.',
            );

            return [
              ProduktEntitiesActions.readProdukteSuccess({produktDtos: produktPageDto.content}),
              ProduktEntitiesActions.countProdukteElementsSuccess({totalElements: produktPageDto.totalElements}),
              ProduktEntitiesActions.countProduktePagesSuccess({totalPages: Math.ceil(produktPageDto.totalElements / (produktPageDto.pageable.size || 1))}),
              ...(successAction ? [successAction({produktDtos: produktPageDto.content})] : []),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponse(error),
            switchMap(error => {
              this.logger.error(
                'read produkte failed.',
                'error:',
                error,
              );

              return [
                ProduktEntitiesActions.readProdukteFailed({error}),
                ...(failureAction ? [failureAction({error})] : []),
              ];
            }),
          )),
        );
      }),
    )
  );

  /**
   * Generisches Error-Handling für das Laden von Produkten.
   */
  readonly readProdukteFailed$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.readProdukteFailed),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden von Produkten. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Keine Produkte gefunden.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Produkte. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Effekt zum Laden eines Produktes anhand der ID, sofern es noch nicht im Store vorhanden ist.
   */
  readonly loadProduktByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.loadProduktByIdIfAbsent),
      concatMap(action => this.store.select(ProduktEntitiesSelectors.produktById(action.produktId)).pipe(
        take(1),
        map(produkt => ({action, produkt}))
      )),
      filter(data => !data.produkt),
      map(data => ProduktEntitiesActions.getProduktById({
        betriebId: data.action.betriebId,
        produktId: data.action.produktId,
      })),
    ),
  );

  /**
   * Effekt zum Laden eines Produkts anhand der ID.
   */
  readonly getProduktById$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.getProduktById),
      concatMap(({betriebId, produktId}) => {
        return this.produktService.getProdukt(betriebId, produktId).pipe(
          map((produktDto) => {
            this.logger.debug(
              'read produktDto by ID succeeded. produktDto:',
              produktDto,
            );

            return ProduktEntitiesActions.getProduktByIdSuccess({produktDto});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read produkt by ID failed. produktId:',
                produktId,
                'error:',
                error,
              );

              return ProduktEntitiesActions.getProduktByIdFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  readonly getProduktByIdFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.getProduktByIdFailure),
      map(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden des Produkts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Produkt nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Produkts. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );

  /**
   * Effekt zum Erstellen eines Produkts.
   */
  readonly createProdukt$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.createProdukt),
      concatMap(({
                   betriebId,
                   requestDto,
                   addToInvoice,
                 }) => {
        return this.produktService.createProdukt(betriebId, requestDto).pipe(
          concatMap(produktDto => {
            this.logger.debug(
              'create produkt entity succeeded. produktId:',
              produktDto.id,
            );

            const successActions: TypedAction<any>[] = [
              ProduktEntitiesActions.createProduktSuccess({produktDto}),
              ProduktEntitiesActions.detectNextProduktnummer({betriebId}),
            ];

            if (addToInvoice) {
              successActions.push(FakturierungsbelegFormActions.addPosition({
                produktDto,
              }));

              // INFO: Das neue Produkt in die Liste der möglichen Produkte des Belegs hinzufügen.
              successActions.push(AddPositionActions.addDisplayedId({
                produktId: produktDto.id,
              }));
            }

            return successActions;
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'create produkt entity failed',
                'error:',
                error,
              );

              return ProduktEntitiesActions.createProduktFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Erstellen eines Produkts
   */
  readonly createProduktSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.createProduktSuccess),
      tap(() => {
        this.snackbar.open('Produkt erfolgreich angelegt', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Erstellen eines Produkts.
   */
  readonly createProduktFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.createProduktFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Erstellen des Produkts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          case 461: {
            errorMsg = 'Produktnummer bereits vergeben. Bitte wähle eine andere Produktnummer.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Erstellen des Produkts. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Updaten eines Produkts.
   */
  readonly updateProdukt$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.updateProdukt),
      concatMap(({
                   betriebId,
                   produktId,
                   requestDto,
                 }) => {
        return this.produktService.updateProdukt(betriebId, produktId, requestDto).pipe(
          switchMap(produktDto => {
            this.logger.debug(
              'update produkt entity succeeded. produktId:',
              produktId,
            );
            return [
              ProduktEntitiesActions.updateProduktSuccess({produktDto}),
              ProduktDialogActions.close(),
            ];
          }),

          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'update produkt entity failed. produktId:',
                produktId,
                'error:',
                error,
              );
              return ProduktEntitiesActions.updateProduktFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Updaten eines Produkts.
   */
  readonly updateProduktSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.updateProduktSuccess),
      tap(() => {
        this.snackbar.open('Erfolgreich aktualisiert', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Updaten eines Produkts.
   */
  readonly updateProduktFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.updateProduktFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Aktualisieren des Produkts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Produkts. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Löschen eines Produkts.
   */
  readonly deleteProdukt$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.deleteProdukt),
      concatMap(({betriebId, produktId}) => {
        return this.produktService.deleteProdukt(betriebId, produktId).pipe(
          switchMap(() => {
            this.logger.debug(
              'delete produkt succeeded. produktId:',
              produktId,
            );

            return [
              ProduktEntitiesActions.deleteProduktSuccess({produktId}),
              DeleteProduktDialogActions.close(),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'delete produkt failed.',
                'error:',
                error,
              );

              return ProduktEntitiesActions.deleteProduktFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Löschen eines Produkts.
   */
  readonly deleteProduktSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.deleteProduktSuccess),
      tap(() => {
        this.snackbar.open('Produkt erfolgreich gelöscht', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Löschen eines Produkts.
   */
  readonly deleteProduktFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.deleteProduktFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Produkts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Produkt nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Produkts. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Laden nächsten verfügbaren Produktnummer
   */
  readonly detectNextProduktnummer$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.detectNextProduktnummer),
      switchMap(({betriebId}) => {
        return this.produktService.readProdukte(betriebId, {
          size: 1,
          number: 0,
          direction: [DirectionDTO.Desc],
          property: ['nummer'],
        }).pipe(
          map((produkt) => {
            this.logger.debug(
              'read next produktnummer succeeded. produktnummer:',
              produkt,
            );

            return ProduktEntitiesActions.detectNextProduktnummerSuccess({produkt, betriebId});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read next produktnummer failed.',
                'error:',
                error,
              );

              return ProduktEntitiesActions.detectNextProduktnummerFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  /**
   * Failure-Handling für das Laden der nächsten verfügbaren Produktnummer.
   */
  readonly detectNextProduktnummerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProduktEntitiesActions.detectNextProduktnummerFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden der nächsten Produktnummer. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Keine Produktnummer gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der nächsten Produktnummer. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    ),
  );

}
