import {createAction, props} from '@ngrx/store';
import {FakturierungsbelegFormComponentMode} from '../../types/fakturierungsbeleg-form-component-mode.type';
import {
  BelegDTO,
  CreateNachlaufzeileRequestDTO,
  PositionDTO,
  ProduktDTO,
  UpdateNachlaufzeileRequestDTO
} from '../../openapi/fakturierung-openapi';
import {PositionAnzahlMode} from '../../interfaces/position-anzahl-modes.interface';
import {PositionEinzelpreisNettoMode} from '../../interfaces/position-betragssumme-mode.interface';
import {DateSelectingMode, MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {UpdatePosition} from '../../modules/components/fakturierungsbelege-form/invoice-table/invoice-table.component';


export class FakturierungsbelegFormActions {

  private static readonly prefix = '[Fakturierungsbeleg Form] ';

  public static reset = createAction(
    this.prefix + 'Reset this context.',
  );

  public static updateFormValidity = createAction(
    this.prefix + 'Update validity of the Beleg.',
  );

  public static updateFakturierungsbelegDto = createAction(
    this.prefix + 'Update the selected fakturierungsbeleg data.',
    props<{
      fakturierungsbelegDto: BelegDTO,
    }>(),
  );

  public static changeLeistungsempfaengerMode = createAction(
    this.prefix + 'Change Leistungsempfaenger Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeVorlaufzeileMode = createAction(
    this.prefix + 'Change Vorlaufzeile Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static addNachlaufzeile = createAction(
    this.prefix + 'Add Nachlaufzeile',
    props<{
      createNachlaufzeileRequestDTO: CreateNachlaufzeileRequestDTO,
      belegId: string,
    }>(),
  );

  public static addNachlaufzeileSuccess = createAction(
    '[Fakturierungsbeleg Form] Add Nachlaufzeile Success',
    props<{
      belegId: string,
    }>(),
  );

  public static addNachlaufzeileFailure = createAction(
    '[Fakturierungsbeleg Form] Add Nachlaufzeile Failure',
    props<{
      error: any,
    }>(),
  );

  public static removeNachlaufzeile = createAction(
    this.prefix + 'Remove Nachlaufzeile',
    props<{
      nachlaufzeileId: string,
      belegId: string,
    }>(),
  );

  public static removeNachlaufzeileSuccess = createAction(
    '[Fakturierungsbeleg Form] Remove Nachlaufzeile Success',
    props<{
      belegId: string,
    }>(),
  );

  public static removeNachlaufzeileFailure = createAction(
    '[Fakturierungsbeleg Form] Remove Nachlaufzeile Failure',
    props<{
      error: any,
    }>(),
  );

  public static updateNachlaufzeile = createAction(
    '[Fakturierungsbeleg Form] Update Nachlaufzeile',
    props<{
      nachlaufzeileId: string,
      updateNachlaufzeileRequestDTO: UpdateNachlaufzeileRequestDTO,
      belegId: string,
    }>(),
  );

  public static updateNachlaufzeileSuccess = createAction(
    '[Fakturierungsbeleg Form] Update Nachlaufzeile Success',
    props<{
      belegId: string,
    }>(),
  );

  public static updateNachlaufzeileFailure = createAction(
    '[Fakturierungsbeleg Form] Update Nachlaufzeile Failure',
    props<{
      error: any,
    }>(),
  );

  public static selectLeistungsempfaenger = createAction(
    this.prefix + 'Select Leistungsempfänger',
    props<{
      absenderId?: string,
    }>(),
  );

  public static addPosition = createAction( // TODO: add loading property. so loading spinner can be displayed.
    this.prefix + 'Add Position to the Fakturierungsbeleg-Form.',
    props<{
      produktDto: ProduktDTO,
    }>(),
  );

  public static addPositionSuccess = createAction(
    this.prefix + 'Added Position to the Fakturierungsbeleg-Form successfully.',
    props<{
      newlyCreatedPosition: PositionDTO,
    }>(),
  );

  public static addPositionFailure = createAction(
    this.prefix + 'Adding Position to the Fakturierungsbeleg-Form failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static deletePosition = createAction(
    this.prefix + 'delete Position',
    props<{ positionId: string }>(),
  );

  public static deletePositionSuccess = createAction(
    this.prefix + 'deleted Position successfully.',
    props<{ positionId: string }>(),
  );

  public static deletePositionFailure = createAction(
    this.prefix + 'deleting Position failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static changePositionAnzahlMode = createAction(
    this.prefix + 'Change Position Anzahl Mode.',
    props<{
      positionAnzahlMode: PositionAnzahlMode,
    }>(),
  );

  // INFO: one same action to update Menge or Einzelpreisnetto, because the same payload is required.
  public static updatePosition = createAction( // TODO: add loading state to this operation.
    this.prefix + 'Update Position.',
    props<{
      updatePositionObj: UpdatePosition,
    }>(),
  );

  public static updatePositionSuccess = createAction(
    this.prefix + 'Update Position Success',
    props<{ newlyUpdatedPosition: PositionDTO }>(),
  );

  public static updatePositionFailure = createAction(
    this.prefix + 'Update Position failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static fetchPositionen = createAction(
    this.prefix + 'Fetch Positionen of current Beleg.',
    props<{ belegId: string }>(),
  );

  public static fetchPositionenSuccess = createAction(
    this.prefix + 'Fetched Positionen of current Beleg successfully',
    props<{ positionen: PositionDTO[] }>(),
  );

  public static fetchPositionenFailure = createAction(
    this.prefix + 'Fetched Positionen of current Beleg failed',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static changePositionEinzelpreisNettoMode = createAction(
    this.prefix + 'Change Position EinzelpreisNetto Mode.',
    props<{
      positionEinzelpreisNettoMode: PositionEinzelpreisNettoMode,
    }>(),
  );

  public static changeAddPositionMode = createAction(
    this.prefix + 'Change Add-Produkt Mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeRechnungsdatumMode = createAction(
    this.prefix + 'Change rechnungsdatum mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeLeistungsLieferDatumControlMode = createAction(
    this.prefix + 'Change leistungs-liefer-datum control mode.',
    props<{
      mode: FakturierungsbelegFormComponentMode,
    }>(),
  );

  public static changeLeistungsLieferDatumDateMode = createAction(
    this.prefix + 'Change leistungs-liefer-datum date selecting mode.',
    props<{
      mode: DateSelectingMode,
    }>(),
  );

  public static setRechnungsdatum = createAction(
    this.prefix + 'Set Rechnungsdatum.',
    props<{
      rechnungsdatum: string,
    }>(),
  );

  // FIXME: When openAPI is updated/implemented by backend team, implement Reducers and Effects for these actions
  // Potential refactor : use only one single action to update the Store.
  public static setLeistungsLieferDatumSingle = createAction(
    this.prefix + 'Set leistungs-liefer-datum single date.',
    props<{
      leistungsLieferDatum: string,
    }>(),
  );

  public static setLeistungsLieferDatumRange = createAction(
    this.prefix + 'Set leistungs-liefer-datum range date.',
    props<{
      datumVon: string,
      datumBis: string,
    }>(),
  );

  public static getLogo = createAction(
    this.prefix + 'Get logo.',
    props<{ betriebId: string }>(),
  );

  public static getLogoSuccess = createAction(
    this.prefix + 'Get logo successfully.',
    props<{ logoUrl: string | null }>(),
  );

  public static getLogoFailure = createAction(
    this.prefix + 'Get logo failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static saveLogo = createAction(
    this.prefix + 'Save logo.',
    props<{
      betriebId: string,
      logoUrl: string,
    }>(),
  );

  public static saveLogoSuccess = createAction(
    this.prefix + 'Save logo successfully.',
    props<{ logoUrl: string }>(),
  );

  public static saveLogoFailure = createAction(
    this.prefix + 'Save logo failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static deleteLogo = createAction(
    this.prefix + 'Delete logo.',
    props<{ betriebId: string }>(),
  );

  public static deleteLogoSuccess = createAction(
    this.prefix + 'Delete logo successfully.',
  );

  public static deleteLogoFailure = createAction(
    this.prefix + 'Delete logo failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static releaseLogoFromMemory = createAction(
    this.prefix + 'Release logo from browser memory.',
    props<{ logoUrl?: string }>(),
  );

  public static redirectToForm = createAction(
    this.prefix + 'Redirect to form.',
    props<{
      betriebId: string,
      belegId: string,
    }>()
  );

}
