import {FakturierungsbelegEntitiesState} from '../states/fakturierungsbeleg-entities.state';
import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';


export const initialFakturierungsbelegEntitiesState: FakturierungsbelegEntitiesState = fakturierungsbelegEntitiesAdapter.getInitialState({
  isLoading: false,
  totalElements: 0,
  createSaveActionSuccessful: false,
  deleteActionSuccessful: false,
  createIsLoading: false,
  finalizeIsLoading: false,
});

export const fakturierungsbelegEntitiesReducer = createReducer(
  initialFakturierungsbelegEntitiesState,

  on(FakturierungsbelegEntitiesActions.readFakturierungsbelegeSuccess, (state, {belegDtos}) => {
    return fakturierungsbelegEntitiesAdapter.setAll(belegDtos, {
      ...state,
      isLoading: false,
      totalElements: belegDtos.length,
    });
  }),

  on(FakturierungsbelegEntitiesActions.readFakturierungsbelegeFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(FakturierungsbelegEntitiesActions.countFakturierungsbelegeElementsSuccess, (state, {totalElements}) => ({
    ...state,
    totalElements,
  })),

  on(FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdSuccess, (state, {belegDto}) => {
    return fakturierungsbelegEntitiesAdapter.upsertOne(belegDto, state);
  }),

  on(FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(FakturierungsbelegEntitiesActions.createFakturierungsbeleg, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
    createIsLoading: true,
  })),

  on(FakturierungsbelegEntitiesActions.createFakturierungsbelegSuccess, (state, {belegDto}) => {
    return fakturierungsbelegEntitiesAdapter.addOne(belegDto, {
      ...state,
      totalElements: state.totalElements + 1,
      createSaveActionSuccessful: true,
      createIsLoading: false,
    });
  }),

  on(FakturierungsbelegEntitiesActions.createFakturierungsbelegFailure, (state) => ({
    ...state,
    createSaveActionSuccessful: false,
    createIsLoading: false,
  })),

  on(FakturierungsbelegEntitiesActions.updateFakturierungsbelegSuccess, (state, {belegDto}) => {
    return fakturierungsbelegEntitiesAdapter.updateOne({id: belegDto.id, changes: belegDto}, state);
  }),

  on(FakturierungsbelegEntitiesActions.updateFakturierungsbelegFailure, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(FakturierungsbelegEntitiesActions.deleteFakturierungsbeleg, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  on(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess, (state, {belegId}) => {
    return fakturierungsbelegEntitiesAdapter.removeOne(belegId, {
      ...state,
      totalElements: state.totalElements - 1,
      deleteActionSuccessful: true,
    });
  }),

  on(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure, (state) => ({
    ...state,
    deleteActionSuccessful: false,
  })),

  on(FakturierungsbelegEntitiesActions.finalizeFakturierungsbeleg, (state) => {
    return {
      ...state,
      finalizeIsLoading: true,
    };
  }),

  on(FakturierungsbelegEntitiesActions.finalizeFakturierungsbelegSuccess, (state, {belegDto}) => {
    return fakturierungsbelegEntitiesAdapter.upsertOne(belegDto, {
      ...state,
      finalizeIsLoading: false,
    });
  }),

  on(FakturierungsbelegEntitiesActions.finalizeFakturierungsbelegFailure, (state) => {
    return {
      ...state,
      finalizeIsLoading: false,
    };
  }),

);
