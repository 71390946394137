import {TableSettings} from '../../interfaces/table-setting.interface';
import {PageableDTO} from '../../openapi/fakturierung-openapi';


export enum AbsenderColumn {
  Titel = 'titel',
  Anrede = 'anrede',
  NameVorname = 'nameVorname',
  Firma = 'firma',
  Kundennummer = 'kundenNummer',
  UstNummer = 'ustNummer',
  StrasseUndHausnummer = 'strasseUndHausnummer',
  Ort = 'adresse',
  Land = 'land',
  Plz = 'plz',
  Email = 'email',
  Actions = 'actions',
}

export interface KundeTableState {
  displayedIds: string[];
  tableSettings: TableSettings;
  pageableDto: PageableDTO;
  totalPages: number
  kundenLoaded: boolean;
}
