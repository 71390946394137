import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegColumn, FakturierungsbelegTableState} from '../states/fakturierungsbeleg-table.state';
import {FakturierungsbelegTableActions} from '../actions/fakturierungsbeleg-table.actions';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';
import {DirectionDTO} from '../../openapi/fakturierung-openapi';


export const initialFakturierungsbelegTableState: FakturierungsbelegTableState = {
  displayedIds: [],
  tableSettings: {
    _key: 'fakturierungsbeleg-table-settings-2024-12-20',
    _columns: [
      {
        id: FakturierungsbelegColumn.Status,
        name: 'Status',
        isActive: true,
        sortable: false,
      }, {
        id: FakturierungsbelegColumn.Rechnungsnummer,
        name: 'Rechn.-Nr.',
        isActive: true,
        sortable: false,
      }, {
        id: FakturierungsbelegColumn.Kunde,
        name: 'Kunde',
        isActive: true,
        sortable: false,
      }, {
        id: FakturierungsbelegColumn.Rechnungsdatum,
        name: 'Rechn.-Dat.',
        isActive: true,
        sortable: false,
      },
      {
        id: FakturierungsbelegColumn.Nettobetrag,
        name: 'Netto',
        isActive: true,
        sortable: false,
        alignCell: 'right',
        alignHeader: 'right',
      }, {
        id: FakturierungsbelegColumn.Bruttobetrag,
        name: 'Brutto',
        isActive: true,
        sortable: false,
        alignCell: 'right',
        alignHeader: 'right',
      }, {
        id: FakturierungsbelegColumn.Actions,
        name: '',
        isActive: true,
        sortable: false,
        lastCol: true,
      },
    ],
    _sortDirs: [],
    _sortParams: [],
  },
  pageableDto: {
    size: 50,
    number: 0,
    property: [
      'erstellt',
    ],
    direction: [
      DirectionDTO.Desc,
    ],
  },
  totalPages: 0,
  isLoaded: false,
};

export const fakturierungsbelegTableReducer = createReducer(
  initialFakturierungsbelegTableState,

  on(FakturierungsbelegTableActions.setDisplayedIds, (state, {belegDtos}) => ({
    ...state,
    displayedIds: belegDtos.map(belegDto => belegDto.id),
  })),

  on(FakturierungsbelegTableActions.clearDisplayedFakturierungsbelege, (state) => ({
    ...state,
    displayedIds: [],
  })),

  on(
    FakturierungsbelegTableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    FakturierungsbelegTableActions.changePage,
    (state, action) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        ...action.pageableDto,
      },
    })
  ),

  on(
    FakturierungsbelegTableActions.updateSearchValue,
    (state, action) => ({
      ...state,
      filterableDto: {
        filter: action.searchValue,
      }
    })
  ),

  on(
    FakturierungsbelegEntitiesActions.countFakturierungsbelegePagesSuccess,
    (state, {totalPages}) => ({
      ...state,
      totalPages,
    })
  ),

  on(
    FakturierungsbelegEntitiesActions.readFakturierungsbelegeSuccess,
    (state) => ({
      ...state,
      isLoaded: true,
    })
  ),
);
