import {inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {from, observable, take} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {InhaberEntitiesActions, InhaberEntitiesSelectors} from '@adnova/jf-ng-components';
import {FakturierungsbelegEntitiesActions} from '../store/actions/fakturierungsbeleg-entities.actions';
import {FakturierungsbelegFormSelectors} from '../store/selectors/fakturierungsbeleg-form.selectors';
import {takeUntil} from 'rxjs/operators';


export interface ExampleDataV1 {
  readonly exampleId: string;
}

export interface PopupOpenDataV1 {
  readonly betriebId: string;
}

export interface UpdatedBetriebDataV1 {
  readonly betriebId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  private _logger = inject(NGXLogger);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _store = inject(Store<AppState>);

  private readonly _exampleV1 = 'de.just-farming:example:example';

  private readonly _popupOpenV1 = 'de.just-farming:fakturierung:popup.edit-betriebsinformationen';

  private readonly _betriebUpdatedIdV1 = 'de.just-farming:betriebsinformationen:betrieb.updated';

  constructor() {
    if (this.isRunningInPortal()) {
      this._portalMessageBroker.registerIntentCallback(this._exampleV1, '1', (data => {
        this.handleExampleV1(data);
      }));

      this._portalMessageBroker.registerIntentCallback(this._betriebUpdatedIdV1, '1', (data => {
        this.handleBetriebUpdated(data);
      }));

      this._portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft, ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this._portalMessageBroker.isRunningInPortal();
  }

  public doExampleV1(
    data: ExampleDataV1,
  ): void {
    this.doEmit(this._exampleV1, '1', data);
  }

  handleExampleV1(
    data: ExampleDataV1
  ): void {
    // TODO impl
  }

  /**
   * Öffnet das Popup zum Bearbeiten der Betriebsinformationen aus der Fakturierung.
   *
   * @param data
   */
  public doPopupOpenV1(
    data: PopupOpenDataV1,
  ): void {
    this.doEmit(this._popupOpenV1, '1', data);
  }

  /**
   * Löst das Aktualisieren der Daten des Betriebs aus.
   *
   * @param data
   */
  handleBetriebUpdated(
    data: UpdatedBetriebDataV1
  ): void {
    const betriebId = data.betriebId;
    this._logger.debug('betrieb updated intent received for betriebId:', betriebId);

    // INFO: Die Daten der Betriebe in der Betriebsauswahl müssen aktualisiert werden.
    this._store.dispatch(InhaberEntitiesActions.loadInhabers());

    /*
     * INFO:
     * Befindet sich parallel ein Beleg in Bearbeitung, so müssen die Daten in der UI aktualisiert werden.
     * Dies kann erreicht werden, wenn der Beleg einmalig gespeichert wird.
     * Anschließend erhalten wir vom Fakturierung-Service die neuen Daten, um diese darzustellen.
     */
    this._store.select(FakturierungsbelegFormSelectors.fakturierungsbeleg).pipe(
      take(1),
    ).subscribe(beleg => {
      // INFO: Wenn kein Beleg in Bearbeitung ist, dann wird nichts aktualisiert.
      if (!beleg.id) return;

      this._store.dispatch(FakturierungsbelegEntitiesActions.updateFakturierungsbeleg({
        betriebId,
        belegId: beleg.id,
        updateBelegRequestDto: {
          kundeId: beleg.kundeId,
          datum: beleg.datum || '',
          vorlaufzeile: beleg.vorlaufzeile,
        },
        fetchPositions: false,
      }));
    });
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this._portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
