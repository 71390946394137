import {AddPositionState} from '../states/add-position.state';
import {createReducer, on} from '@ngrx/store';
import {AddPositionActions} from '../actions/add-position.actions';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {KundeLeistungsempfaengerActions} from '../actions/kunde-leistungsempfaenger.actions';


export const initialAddPositionState: AddPositionState = {
  displayedIds: [],
  pageableDto: {
    size: 15,
    number: 0,
  },
  readMoreProdukte: false,
};

export const addPositionReducer = createReducer(
  initialAddPositionState,

  on(AddPositionActions.setDisplayedIds, (state, {produktDtos}) => ({
    ...state,
    displayedIds: produktDtos.map(produktDto => produktDto.id!),
  })),

  on(
    AddPositionActions.addDisplayedIds,
    (state, {produktDtos}) => ({
      ...state,
      displayedIds: [
        ...state.displayedIds,
        ...produktDtos.map(produktDto => produktDto.id!),
      ],
    }),
  ),

  on(AddPositionActions.addDisplayedId, (state, {produktId}) => {
    let updatedDisplayedIds = [...state.displayedIds];
    if (!updatedDisplayedIds.includes(produktId!)) {
      updatedDisplayedIds = [
        ...updatedDisplayedIds,
        produktId!,
      ];
    }

    return {
      ...state,
      displayedIds: updatedDisplayedIds,
    };
  }),

  on(AddPositionActions.clearDisplayedProdukte, (state) => ({
    ...state,
    displayedIds: [],
  })),

  on(FakturierungsbelegFormActions.changeAddPositionMode, (state, {mode}) => {
    if (mode === 'display') {
      return {
        ...state,
        readMoreProdukte: false,
        pageableDto: {
          ...initialAddPositionState.pageableDto,
        },
      };
    }

    return {
      ...state,
    };
  }),

  on(AddPositionActions.triggerReadMoreProdukte, (state) => ({
    ...state,
    readMoreProdukte: true,
    pageableDto: {
      ...state.pageableDto,
      number: (state.pageableDto.number || 0) + 1,
    }
  })),
);
