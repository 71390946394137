import {inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {take, throttleTime} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {FakturierungsbelegContentActions} from '../store/actions/fakturierungsbeleg-content.actions';


@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {

  private _logger = inject(NGXLogger);
  private _store = inject(Store<AppState>);

  // INFO: Es wird ein SubjectMap erstellt, um die gestarteten Downloads zu verwalten
  private _downloadSubjects: Map<string, Subject<{ file: Blob | string; fileName: string }>> = new Map();

  /** INFO:
   * Public API für den Download von Blobs und Base64-Strings
   * Startet den Download eines Blobs oder Base64-Strings.
   * @param file - Der Blob oder Base64-String, der heruntergeladen werden soll.
   * @param fileName - Der Name der herunterzuladenden Datei.
   * @param dataID - Die eindeutige ID für den Download.
   * @param timeOut - Die Zeit in Millisekunden, die zwischen Downloads gewartet werden soll.
   */

  downloadPDF(
    file: Blob | string,
    fileName: string = 'beleg.pdf',
    fileID: string,
    isPreviewMode: boolean = false,
    timeOut: number = 0,
  ): void {
    if (!this._downloadSubjects.has(fileID)) {
      this._downloadSubjects.set(fileID, new Subject());
      this.throttleDownload(fileID, isPreviewMode, timeOut);
    }

    this._downloadSubjects.get(fileID)?.next({file, fileName});
  }

  /**
   * Setzt Throttling für eine bestimmte dataId.
   * Am Ende des Throttling-Zeitraums das Subject aus der Map wieder entfernt um Speicher zu sparen.
   * @param dataId - Die eindeutige ID für den Download.
   * @param time - Die Zeit in Millisekunden, die zwischen Downloads gewartet werden soll.
   */
  private throttleDownload(dataId: string, isPreviewMode: boolean, time: number): void {
    const subject = this._downloadSubjects.get(dataId);

    if (!subject) {
      return;
    }

    subject.pipe(
      throttleTime(time),
      take(1)
    ).subscribe(({file, fileName}) => {
      if (typeof file === 'string') {
        this.triggerDownload(this.convertFromBase64(file), isPreviewMode, fileName);
      } else {
        this.triggerDownload(file, isPreviewMode, fileName);
      }

      // Nach dem Throttle-Timout wird das Subject aus der Map entfernt
      setTimeout(() => {
        this._downloadSubjects.delete(dataId);
      }, time);
    });
  }

  /**
   * Startet den Download eines Blobs.
   * @param blob - Der Blob, der heruntergeladen werden soll.
   * @param fileName - Der Name der herunterzuladenden Datei.
   */

  private triggerDownload(blob: Blob, isPreviewMode: boolean, fileName: string = 'download.pdf'): void {
    try {
      // INFO: Object-URL erstellen
      const objectUrl = URL.createObjectURL(blob);

      if (isPreviewMode) {
        this._store.dispatch(FakturierungsbelegContentActions.createPdfPreviewObjectUrlSuccess({objectUrl}));
      } else {
        // INFO: Link erstellen und die PDF-URL setzen
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;
        document.body.appendChild(link);

        // INFO: Klick simulieren, um den Download zu starten.
        link.click();

        // INFO: URL freigeben, um Speicher zu sparen.
        document.body.removeChild(link);
        this.revokeObjectUrl(objectUrl);
      }
    } catch (error) {
      this._logger.error('Fehler beim Herunterladen der Datei:', error);
    }
  }

  /**
   * Entfernt eine Object-URL aus dem Speicher.
   *
   * @param objectUrl
   */
  revokeObjectUrl(objectUrl: string): void {
    URL.revokeObjectURL(objectUrl);
  }

  /**
   * Dekodiert einen Base64-String und wandelt ihn in einen Blob um.
   * Ist der Base64-String ungültig, wird ein leerer Blob zurückgegeben, der keine Datei enthält.
   * @param base64String - Der Base64-String, der dekodiert werden soll.
   */
  private convertFromBase64(base64String: string): Blob {
    try {
      const byteCharacters = atob(base64String);
      const byteArray = Uint8Array.from(byteCharacters, c => c.charCodeAt(0));
      const blob = new Blob([byteArray], {type: 'application/pdf'});

      return blob;
    } catch (error) {
      this._logger.error('Fehler beim Konvertieren des Base64 Strings in einen Blob:', error);
      return new Blob();
    }
  }
}
