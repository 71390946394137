import {Component, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {ProduktEntitiesSelectors} from '../../../store/selectors/produkt-entities.selectors';
import {DeleteProduktDialogSelectors} from '../../../store/selectors/delete-produkt-dialog.selectors';
import {take} from 'rxjs';
import {BerechnungsregelMengeMalEinzelpreisDTO, ProduktDTO} from '../../../openapi/fakturierung-openapi';
import {DeleteProduktDialogActions} from '../../../store/actions/delete-produkt-dialog.actions';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProduktEntitiesActions} from '../../../store/actions/produkt-entities.actions';
import {UmsatzsteuerschluesselExtended} from '../../../interfaces/umsatzsteuerschluessel-extended.interface';
import {
  UmsatzsteuerschluesselEntitiesSelectors
} from '../../../store/selectors/umsatzsteuerschluessel-entities.selectors';


@Component({
  selector: 'app-delete-produkt-dialog',
  templateUrl: './delete-produkt-dialog.component.html',
  styleUrls: ['./delete-produkt-dialog.component.scss'],
})
export class DeleteProduktDialogComponent implements OnInit {

  protected produktDto?: ProduktDTO;
  protected umsatzsteuerschluessel?: UmsatzsteuerschluesselExtended;
  protected prozentsatz?: number;

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.store.select(DeleteProduktDialogSelectors.deleteData).pipe(
      take(1),
    ).subscribe(data => {

      if (data.produktId) {
        this.store.select(ProduktEntitiesSelectors.produktById(
          data.produktId,
        )).pipe(
          take(1),
        ).subscribe(produktDto => {
          this.produktDto = produktDto;
        });
      } else {
        this.logger.warn('delete dialog produktId is not set');
      }
    });

    const betriebId = this.produktDto?.betriebId;
    const berechnungsregel = this.produktDto?.berechnungsregel as BerechnungsregelMengeMalEinzelpreisDTO;
    const umsatzsteuerschluesselNummer = berechnungsregel?.umsatzsteuerschluesselNummer;

    if (betriebId) {
      this.store.select(UmsatzsteuerschluesselEntitiesSelectors.umsatzsteuerschluesselByBetriebAndNummer(
        betriebId, umsatzsteuerschluesselNummer
      )).pipe(
        take(1),
      ).subscribe(umsatzsteuerschluessel => {
        this.umsatzsteuerschluessel = umsatzsteuerschluessel;
        this.prozentsatz = undefined;
        if (!umsatzsteuerschluessel) return;
        this.prozentsatz = umsatzsteuerschluessel?.prozentsatz / 100;
      });
    }

  }

  protected deleteProduktClick(): void {
    this.store.dispatch(ProduktEntitiesActions.deleteProdukt({
      betriebId: this.produktDto!.betriebId,
      produktId: this.produktDto!.id
    }));
  }

  protected closeDialogClick(): void {
    this.store.dispatch(DeleteProduktDialogActions.close());
  }
}
