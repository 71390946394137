import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegContentState} from '../states/fakturierungsbeleg-content.state';
import {FakturierungsbelegContentActions} from '../actions/fakturierungsbeleg-content.actions';


export const initialFakturierungsbelegContentState: FakturierungsbelegContentState = {};

export const fakturierungsbelegContentReducer = createReducer(
  initialFakturierungsbelegContentState,

  on(
    FakturierungsbelegContentActions.createPdfPreviewObjectUrlSuccess,
    (state, action) => ({
      ...state,
      pdfPreviewObjectUrl: action.objectUrl,
    })
  ),

  on(
    FakturierungsbelegContentActions.revokeObjectUrl,
    (state) => ({
      ...state,
      pdfPreviewObjectUrl: undefined,
    })
  ),
);
