<div *ngIf="inhaberId !== undefined" class="icon-link-wrapper">
  <jf-link
    *ngFor="let navigationLink of navigationLinks"
    variant="textlink-icon"
    [routerLink]="[navigationLink.route, 'inhaber', inhaberId]"
    [text]="navigationLink.label"
    [iconName]="navigationLink.iconName"
    iconPosition="before"
    routerLinkActive="active"
    i18n-text
  ></jf-link>

  <div class="action-button-wrapper">
    <jf-button
      *ngIf="buttonAction && buttonLabel"
      [variant]="buttonVariant"
      [icon]="buttonIcon"
      [text]="buttonLabel"
      iconPosition="left"
      (click)="doButtonAction()"
      [disabled]="!buttonEnabled"
    ></jf-button>
  </div>
</div>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>
